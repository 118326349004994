import Vue from 'vue'
import { EagleFormConfigInterface } from '@/components/form/types/form'
class formConfig {
  protected vm?: Vue
  protected formKey?: string

  _defaultData() {
    return {
      name: null,
      description: null,
    }
  }

  get(vm: Vue, formKey: string) : EagleFormConfigInterface {
    this.vm = vm
    this.formKey = formKey

    return {
      from: () => [
        { label: 'page.site-admin-group', route: { name: 'site-admin-group' } },
      ],
      listRoute: () => ({ name: 'site-admin-group' }),
      hasWritePermission: (hasRole) : boolean => hasRole(['ROLE_ADMIN']),
      afterCreateRoute: result => ({
        name: 'site-admin-group-update',
        params: { target: result.id },
      }),
      pageTitle: (formMeta: any, formData: AnyObject) => window.eagleLodash.get(formData, ['name']),
      pageTitleBase: () => 'module.site_admin_group',
      defaultData: this._defaultData,
      blocks: [
        {
          title: 'form_block.base',
          data: {
            name: {
              required: true,
              label: 'data.name',
              type: 'text',
              grid: { xl: 12, lg: 12, md: 12 },
              hasChanged: ({originalData, data, bothEmpty}: FormDataChangeHelperInterface) => {
                if(bothEmpty(originalData, data)) return false
                return originalData != data
              },
            },
            description: {
              label: 'data.description',
              grid: { xl: 12, lg: 12, md: 12 },
              type: 'textarea',
              maxlength: 300,
              hasChanged: ({originalData, data, bothEmpty}: FormDataChangeHelperInterface) => {
                if(bothEmpty(originalData, data)) return false
                return originalData != data
              },
            },
            site_admins: {
              label: 'module.site_admin',
              grid: { xl: 12, lg: 12, md: 12 },
              type: 'item-list',
              itemTitle: item => this.vm?.$helper.getSiteUserName(item),
              itemSubtitle: item => item.email,
              itemRoute: (item) => ({
                name: 'site-admin-update',
                params: { target: item.id },
              }),
              defaultAvatarIcon: 'fa fa-user',
              // @ts-ignore
              selector: this.vm?.$siteAdminSelector,
              hasChanged: ({originalData, data, arrayPropertyDiff}: FormDataChangeHelperInterface) => {
                return arrayPropertyDiff(originalData, data, 'id')
              },
            },
            roles: {
              label: 'site_admin.data.roles',
              create: () => true,
              type: 'selection',
              multiple: true,
              options: (formInfo: FormInfoInterface) => {
                if(!formInfo.formMeta) return []
                if(!Array.isArray(formInfo.formMeta.roles)) return []
                return formInfo.formMeta.roles.map(role => ({
                  value: role,
                  text: role,
                }))
              },
              hasChanged: ({originalData, data, bothEmpty}: FormDataChangeHelperInterface) => {
                if(bothEmpty(originalData, data)) return false
                return window.eagleLodash.isEqual(originalData, data) === false
              },
            },
          },
        },

        // 其牠資訊
        {
          title: 'form_block.others',
          create: ({ formMode }: FormInfoInterface) => formMode === 'update',
          data: {
            created_at: {
              label: 'data.created_at',
              type: 'time',
              readonly: true,
            },
            updated_at: {
              label: 'data.updated_at',
              type: 'time',
              readonly: true,
            },
          },
        },
      ],
    }
  }
}

export default new formConfig()
